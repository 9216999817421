import React from "react";
import Layout from "../components/layout";
import { Container, Row, Col } from "react-bootstrap";
import Seo from "../components/seo";
//Images
import { StaticImage } from "gatsby-plugin-image";

const Architects = () => {
    return (
        <Layout>
                            <Seo
      title="Architectes - BIKESAFE"
      description="Bike Safe offre une gamme complète de produits de stationnement pour vélos combinant haute sécurité, esthétique et design sculptural à un prix abordable. Tous les produits proposés par Bike Safe répondent aux exigences des architectes et planificateurs urbains en matière de qualité, de fonctionnalité et d'intégration dans l'architecture urbaine."
    />
            <Container>
                <Row>
                    <Col sm="12">
                        <h1 className="mt-5 mb-5 text-center">
                            Architectes
                        </h1>
                    </Col>
                </Row>
                <Row>
                    <Col lg="6" sm="12">
                        <h5 className="float-lg-left subheading-product uppercase">Qualité et fonctionnalité :</h5>
                    </Col>
                    <Col lg="6" sm="12">
                        <p className="flow-text">
                            Bike Safe offre une gamme complète de produits de stationnement pour vélos combinant haute sécurité, esthétique et design sculptural à un prix abordable. Tous les produits proposés par Bike Safe répondent aux exigences des architectes et planificateurs urbains en matière de qualité, de fonctionnalité et d'intégration dans l'architecture urbaine.
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" className="mt-5 mb-5 text-center">
                        <StaticImage placeholder="blurred"  src="../images/projects.png" className="shadow fluid rounded " />
                    </Col>
                </Row>
                <Row>
                    <Col lg="6" sm="12">
                        <h5 className="float-lg-left subheading-product uppercase">Conception CAO en interne :</h5>
                    </Col>
                    <Col lg="6" sm="12">
                        <p className="flow-text">
                            Bike Safe offre un service complet qui se compose d'un service de conception assistée par ordinateur (CAO). Stephen, notre designer spécialisé dans la CAO, dispose d'une grande expérience pour aider les architectes à développer des designs innovants et uniques, adaptés à l'architecture urbaine. Si vous êtes intéressé(e) par ce service, veuillez nous contacter pour plus d'informations concernant les tarifs et options.
                        </p>
                    </Col>
                </Row>
            </Container>
        </Layout>
    )
}

export default Architects;